<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader
        :pageName="pageName"
        :pageNavs="$permission.isGroup() ? groupNavs : pageNavs"
      />
      <section class="section">
        <div class="row">
          <div class="col-12">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>クーポン名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-trim
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="$permission.isGroup()">
                        <FormRow>
                          <template v-slot:label>店舗名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="shopName"
                                  v-maxlength
                                  maxlength="45"
                                  v-trim
                                  v-model="searchForm.shopName"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>発行元</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="issuer"
                                  v-model="searchForm.issuer"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in issuerTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>公開・非公開</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="validFlag"
                                  v-model="searchForm.validFlag"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in validFlagTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>ステータス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="timePublic"
                                  v-model="searchForm.timePublic"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in newExpiredTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>配布済・未配布</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="isDistribute"
                                  v-model="searchForm.isDistribute"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in distributionTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :storeModule="storeModule"
                    :dataTable="
                      $permission.isGroup() ? dataGroupTable : dataTable
                    "
                    :labels="$permission.isGroup() ? groupLabels : labels"
                    @handle-toggle="handleStatus"
                    @select-items="handleSelectedItems"
                    ref="table"
                    :count="couponCount"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :shopId="shopId"
                  :listLength="couponCount"
                  :searchConditions="searchConditions"
                  ref="pagination"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button class="btn btn-red" :disabled="!isSelectedItemsExisted" type="button" @click="showDeleteModal">
              削除
            </button>
          </li>
          <li v-if="$permission.isStore() || (isGmoOrOfficeLoginStore && validFlag)" class="listGrid-item pos-end">
            <router-link
              class="btn btn-main"
              :to="{ name: 'ShopCouponRegister' }"
              >新規作成</router-link
            >
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('statusModal', toggleFlag)" v-if="statusModal">
      <template v-slot:headline>{{ isPublic ? '公開' : '非公開' }}</template>
      <template v-slot:body>
        <p class="description text-align-center">
          {{
            isPublic
              ? 'このクーポンを公開しますか？'
              : 'このクーポンを非公開にしますか？'
          }}
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="cancelChangeStatus"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-yellow"
              :handle-submit="changeStatus"
              :button-text="isPublic ? '公開する' : '非公開にする'"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('deletedModal')" v-if="deletedModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">
          選択したクーポンを削除しますか？
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="closeModal('deletedModal')"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-red"
              :handle-submit="deleteCoupon"
              button-text="削除する"
            />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import nav from '@/mixins/nav/coupon';
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
//component
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import Modal from '@/components/Modal.vue';
import FormRow from '@/components/FormRow.vue';
//helpers
import { addThreeDotEndLine, breakLineWithComma } from '@/helpers/formatData';
//constants
import { CouponConstants } from '@/constants/coupon';

export default {
  name: 'ShopCoupon',
  data: function() {
    return {
      authority: 'shop',
      pageName: 'クーポン',
      modulePath: this.$permission.isGroup()
        ? 'coupon/getEventCoupons'
        : 'coupon/getShopCoupons',
      storeModule: 'coupon',
      groupNavs: [
        {
          id: '01',
          name: 'クーポン一覧',
          path: '/shop/coupon',
          routeName: 'ShopCoupon',
          includes: false,
        },
        {
          id: '02',
          name: '利用実績',
          path: '/shop/coupon/use',
          routeName: 'ShopCouponUse',
          includes: false,
        },
      ],
      labels: [
        { isCheckbox: true },
        { key: 'image', name: '画像' },
        { key: 'name', name: 'クーポン名', tdClass: 'dataTable-break w-145' },
        { key: 'issuer', name: '発行元' },
        { key: 'totalCoupon', name: '発行枚数' },
        { key: 'distributionCoupon', name: '配布数' },
        { key: 'count', name: '利用回数' },
        { key: 'timePublic', name: 'ステータス', tdClass: 'dataTable-break' },
        { key: 'validFlag', name: '公開' },
        { isButtonColumn: true },
      ],
      groupLabels: [
        { isCheckbox: true },
        { key: 'image', name: '画像' },
        { key: 'name', name: 'クーポン名', tdClass: 'dataTable-break w-145' },
        { key: 'issuer', name: '発行元' },
        { key: 'shopName', name: '店舗名', tdClass: 'dataTable-break w-145' },
        { key: 'totalCoupon', name: '発行枚数' },
        { key: 'distributionCoupon', name: '配布数' },
        { key: 'count', name: '利用回数' },
        { key: 'timePublic', name: 'ステータス', tdClass: 'dataTable-break' },
        { key: 'validFlag', name: '公開' },
      ],
      searchFields: ['name', 'shopName', 'issuer', 'validFlag', 'timePublic', 'isDistribute'],
      searchConditions: {},
      statusModal: false,
      deletedModal: false,
      couponId: null,
      groupShopId : null,
      isPublic: 0,
      selectedItems: {
        allFlag: false,
        id: [],
      },
      issuerTypes: Object.values(CouponConstants.issuerType),
      validFlagTypes: Object.values(CouponConstants.validFlagType),
      expiredTypes: Object.values(CouponConstants.expiredType),
      distributionTypes: Object.values(CouponConstants.distributionType),
    };
  },
  components: {
    PaginationLog,
    TableWithCheckbox,
    Modal,
    FormRow
  },
  mixins: [nav, modal, search],
  computed: {
    ...mapGetters({
      lists: 'coupon/couponList',
      couponCount: 'coupon/couponCount',
      userInfo: 'auth/infor',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
      validFlag: 'shop/validFlag',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
    }),
    dataTable() {
      return (
        this.lists &&
        this.lists.map((item) => {
          return {
            ...item,
            image: {
              type: 'image',
              value: item.image?.url,
            },
            isRowDisabled: item?.isGreyout,
            name: addThreeDotEndLine(item?.name, 15),
            shopName: addThreeDotEndLine(item.shop?.name, 15),
            totalCoupon: item?.remaining === null ? '-' : item.totalCoupon,
            count: item.count || '-',
            timePublic: breakLineWithComma(item?.status),
            validFlag: {
              isToggleButton: true,
              value: item.validFlag,
              id: item?.id,
            },
            buttonLinks: [
              {
                content: '編集',
                class: 'btn btn-sm btn-bd-main',
                routeName: 'ShopCouponEdit',
                params: { id: item?.id },
              },
            ],
          };
        })
      );
    },
    dataGroupTable() {
      return (
        this.lists &&
        this.lists.map((item) => {
          return {
            ...item,
            image: {
              type: 'image',
              value: item.image?.url,
            },
            isRowDisabled: item?.isGreyout,
            name: addThreeDotEndLine(item?.name, 15),
            shopName: addThreeDotEndLine(item.shop?.name, 15),
            totalCoupon: item?.remaining === null ? '-' : item.totalCoupon,
            count: item.count || '-',
            timePublic: breakLineWithComma(item?.status),
            validFlag: {
              isToggleButton: true,
              value: item.validFlag,
              id: item?.id,
              shopId: item.shop?.id,
            },
          };
        })
      );
    },
    newExpiredTypes() {
      const pausedTypes = [CouponConstants.expiredType.pause.value, CouponConstants.expiredType.stop.value];
      return this.isGmoOrOfficeLoginStoreAndStore ? this.expiredTypes.filter(item => !pausedTypes.includes(item.value)) : this.expiredTypes;
    },
    isSelectedItemsExisted() {
      return this.selectedItems.allFlag || this.selectedItems.id.length > 0;
    },
  },
  methods: {
    toggleFlag() {
      this.lists.find((el) => el?.id === this.couponId).validFlag = !this.isPublic;
    },
    handleStatus(id, newStatus, shopId) {
      this.showModal('statusModal');
      this.couponId = id;
      this.groupShopId = shopId;
      this.lists.find((el) => el?.id === id).validFlag = newStatus;
      this.isPublic = newStatus ? 1 : 0;
    },
    async changeStatus() {
      const result = await this.$store.dispatch('coupon/updateCouponStatus', {
        subdomain: this.subdomain,
        shopId: this.shopId || this.groupShopId,
        id: this.couponId,
        data: { status: this.isPublic },
      });
      await this.closeModal('statusModal');
      if (result) this.$message.updated('coupon')
      else this.toggleFlag();
    },
    cancelChangeStatus() {
      this.handleStatus(this.couponId, !this.isPublic);
      this.closeModal('statusModal');
    },
    showDeleteModal() {
      if (this.isSelectedItemsExisted) {
        this.showModal('deletedModal');
      } else {
        this.$message.noSelectedItem();
      }
    },
    async deleteCoupon() {
      let result;
      if (this.isGmoOrOfficeLoginStoreAndStore){
        result = await this.$store.dispatch('coupon/deleteShopCoupon', {
          subdomain: this.subdomain,
          shopId: this.shopId,
          data: this.selectedItems,
        });
      } else {
        result = await this.$store.dispatch('coupon/deleteEventCoupon', {
          subdomain: this.subdomain,
          data: this.selectedItems,
        });
      }
      this.$refs.table.resetCheck();
      this.closeModal('deletedModal');
      if (result) {
        this.$refs.pagination.resetPagination();
        if (result.data.count > 0) this.$message.deleted('coupon');
        else this.$message.showError('deletedBySomeone');
      }
    },
    handleSelectedItems(nVal) {
      this.selectedItems = {
        allFlag: nVal.allFlag,
        id: nVal.ids,
      };
    },
  },
  beforeDestroy() {
    this.$store.commit('coupon/SET_COUPON_LIST', []);
    this.$store.commit('coupon/SET_COUPON_COUNT', 0);
  }
};
</script>
